<template>
  <section>
    <b-card>
      <b-card-title>Histórico de SMS
      </b-card-title>

      <TablePagination
        v-model="items"
        url="/user/services/my_purchases/inactive_numbers/paginate"
        :per-page="10"
        default-filter-by="profile"
        :fields="fields"
      >
        <template v-slot:image="data">
          <img
            class="rounded-lg"
            :src="`${$api_host}/storage/assets/${data.items.image}`"
            width="35px"
          >
        </template>

        <template v-slot:name="data">
          {{ data.items.name }}
        </template>

        <template v-slot:number="data">
          <b-button
            v-clipboard:copy="data.items.number"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.number }}
          </b-button>
        </template>

        <template v-slot:sms="data">
          <b-button
            v-if="data.items.sms !== null"
            v-clipboard:copy="data.items.sms"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            <span>{{ data.items.sms }}</span>
          </b-button>
          <b-button
            v-else
            v-clipboard:copy="data.items.sms"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            <span>Nenhum SMS</span>
          </b-button>
        </template>

        <template v-slot:status="data">
          <b-button
            size="sm"
            :variant="getStatus(data.items.status).color"
          >
            {{ getStatus(data.items.status).message }}
          </b-button>
        </template>

        <template v-slot:created_at="data">
          <b-button
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.created_at | date }}
          </b-button>
        </template>

      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import {
  BSpinner, BCard, BCardTitle, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BSpinner,
    BCard,
    BCardTitle,
    BButton,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 4)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'image',
        label: 'Produto'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'number',
        label: 'Número'
      },
      {
        key: 'sms',
        label: 'sms'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'created_at',
        label: 'data'
      }
    ]
  }),
  methods: {
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    getStatus (status) {
      if (status === 4) {
        return {
          color: 'relief-success',
          message: 'Confirmado'
        }
      }

      if (status === 5) {
        return {
          color: 'relief-danger',
          message: 'Cancelado'
        }
      }

      if (status === 6) {
        return {
          color: 'relief-warning',
          message: 'Reembolsado'
        }
      }
    }
  }
}
</script>
